import React from 'react';
import Headline from '../../ui/Headline';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import { IImage } from '../../ui/Image/types';
import Image from '../../ui/Image';
import * as styles from './styles.module.scss';
import Icon from '../../ui/Icon';

type QuoteTeaserProps = {
  quote: string;
  author: string;
  image: IImage;
};

const QuoteTeaser: React.FC<QuoteTeaserProps> = ({ quote, author, image }) => {
  return (
    <Container additionalClass={styles.container}>
      <Row>
        <Col desktop={6} tablet={12} mobile={12} additionalClass={styles.imgCol}>
          <Image {...image} additionalClass={styles.img} />
        </Col>
        <Col desktop={6} tablet={12} mobile={12} additionalClass={styles.textCol}>
          <Icon name="quote" additionalClass="mb-10 fill-current text-primary" />
          <Headline level={2} additionalClass="desktop:mr-50" color="Black">
            {quote}
          </Headline>
          <div className="mt-40 desktop: mt-50">
            <p className="text-copyGray text-18">{author}</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default QuoteTeaser;
